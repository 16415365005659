<ba-studisu-responsive-kontext-info-header
  [headline]="'Studienbereiche entdecken!'"
  [breadcrumbs]="this.breadcrumbs$ | async"
  [sources]="headerSources"
>
</ba-studisu-responsive-kontext-info-header>
<div class="container-fluid">
  <ba-studisu-suche-form></ba-studisu-suche-form>

  <div
    class="row"
    *ngIf="{
      studienangebote: studienangebote$ | async,
      aggregations: aggregations$ | async
    } as kontext"
  >
    <div class="col-xl-3 col-lg-30 col-md-12">
      <ba-studisu-reset-all-facettes
        *ngIf="kontext.aggregations"
        [facettenWerte]="kontext.aggregations"
      ></ba-studisu-reset-all-facettes>
      <ba-studisu-suche-facettes
        *ngIf="kontext.aggregations"
        [facettenWerte]="kontext.aggregations"
      ></ba-studisu-suche-facettes>
    </div>

    <div class="col-xl-9 col-lg-70 col-md-12">
      <ba-studisu-suche-result
        *ngIf="kontext.studienangebote"
        [searchResult]="kontext.studienangebote"
        [facettenWerte]="kontext.aggregations"
      ></ba-studisu-suche-result>
    </div>
  </div>
</div>
