<ng-container *ngIf="anbieter?.length > 0">
  <ba-bub-facette
    id="studienanbieter"
    icon="ba-icon studisu-icon-anbieter-before"
    facettenName="Anbieter"
    [showReset]="mindestensEinAnbieterGewaehlt"
    [facettenInhalt]="facettenInhalt"
    [druckansicht]="druckansicht"
    (resetClicked)="anbieterAuswahlZurueckSetzen()"
    *ngIf="!showMobile; else mobileFacette"
    [headingLevel]="3"
  >
  </ba-bub-facette>
</ng-container>

<ng-template #facettenInhalt>
  <ba-bub-anbieter-filter
    [komponentenId]="'anbieterFacette'"
    [anbieterListe]="anbieter"
    (anbieterAusgewaehlt)="anbieterAusgewaehlt($event)"
  ></ba-bub-anbieter-filter>
</ng-template>

<ng-template #druckansicht> </ng-template>

<!--mobile-->
<ng-template #mobileFacette>
  <ba-bub-collapsible-facette
    [id]="'studienanbieter-mobil'"
    [icon]="'ba-icon studisu-icon-anbieter-before'"
    [facettenName]="'Anbieter'"
    [anzahlSelektierterFilter]="0"
  >
    <ng-container *ngTemplateOutlet="facettenInhalt"></ng-container>
  </ba-bub-collapsible-facette>
</ng-template>
