import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  CollapsibleFacetteModule,
  FacetteModule,
  FilterSelectItem,
  KarteModule,
  LoggingService,
  RegionKurz
} from '@infosysbub/ng-lib-dpl3';
import { Subscription } from 'rxjs';
import { SearchResult } from 'src/app/model/SearchResult';
import { Facette } from '../../services/model/Facetten';
import { OpenFacetteService } from '../../services/open-facette.service';
import { UrlParamService } from '../../services/url-param.service';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'ba-studisu-regionen-facette',
  templateUrl: './regionen-facette.component.html',
  standalone: true,
  imports: [KarteModule, NgIf, FacetteModule, CollapsibleFacetteModule, NgTemplateOutlet, NgFor]
})
export class RegionenFacetteComponent implements OnInit, OnChanges, OnDestroy {
  private static FILTER_ID = UrlParamService.PARAM_REGION;

  @Input()
  kartenItems: FilterSelectItem[] = [];

  @Input()
  public showMobile = false;

  public hidden = true;

  @ViewChild('svg_germany')
  private svgGermany;

  @ViewChild('svg_europe')
  private svgEurope;

  @Input()
  private searchResult: SearchResult;

  showReset = false;
  private openFacetteSubscription: Subscription;

  /**
   * constructor
   * @param urlParamService (Injected) UrlParamService for triggering url updates
   * @param logger LoggingService for local Browser logs in dev environment
   * @param openFacetteService (Injected) OpenFacetteService for triggering facette openings
   */
  constructor(
    private urlParamService: UrlParamService,
    private logger: LoggingService,
    private openFacetteService: OpenFacetteService
  ) {}

  /**
   * Initialization of this facette. Registers callback on url parameter change
   * which initializes default values. Subscribes on open facette event.
   */
  public ngOnInit() {
    // We do not subscribe to the URLParams because that is not needed here!

    this.openFacetteSubscription = this.openFacetteService
      .getFilterIdOpened()
      .subscribe((filterIdOpened) => {
        if (RegionenFacetteComponent.FILTER_ID !== filterIdOpened && !this.hidden) {
          this.hidden = true;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchResult) {
      const filter: Facette = changes.searchResult.currentValue.facetten.filter(
        (value: Facette) => value.id === UrlParamService.PARAM_REGION
      )[0];

      if (!filter || !filter.auswahl) {
        return;
      }

      const nothingSelected = filter.auswahl.every((item) => !item.preset);

      this.showReset = !nothingSelected;

      this.kartenItems = filter.auswahl.map((item) => {
        const isSelected = item.preset;
        const plus = !nothingSelected && !isSelected ? 'weitere ' : '';
        let ariaLabel = `${item.label} (${item.trefferAnzahl} ${plus}Ergebnisse)`;
        if (1 === item.trefferAnzahl) {
          ariaLabel = ariaLabel.replace('weitere', 'weiteres').replace('Ergebnisse', 'Ergebnis');
        }

        return {
          key: `${item.id}`,
          selected: item.preset,
          ariaLabel: ariaLabel,
          active: true,
          label: item.label,
          treffer: item.trefferAnzahl
        };
      });
    }
    this.hidden = true;
    this.updateResetButtonVisibility();
  }

  /**
   * OnDestroy Hook to unsubscribe made Subscriptions of this component
   */
  public ngOnDestroy() {
    if (this.openFacetteSubscription != null) {
      this.openFacetteSubscription.unsubscribe();
    }
  }

  /**
   * Blendet die Filteroptionen ein bzw. aus
   */
  toggleDisplay() {
    this.hidden = !this.hidden;
    // informiere andere Facetten, dass diese Facette geöffnet wurde
    if (!this.hidden) {
      this.openFacetteService.setFilterIdOpened(RegionenFacetteComponent.FILTER_ID);
    }
  }

  /**
   * Clear all selected Bundeslaender.
   */
  resetFilters() {
    this.urlParamService.updateView({
      [UrlParamService.PARAM_REGION]: null,
      [UrlParamService.PARAM_PAGE]: 1
    });
  }

  regionClicked(regionen: RegionKurz[]) {
    const selectedRegions = regionen.join(UrlParamService.VALUE_SEPARATOR);
    this.urlParamService.updateView({
      [UrlParamService.PARAM_REGION]: selectedRegions,
      [UrlParamService.PARAM_PAGE]: 1
    });
  }

  /**
   * Updates the visibility of the filter reset button.
   */
  private updateResetButtonVisibility() {
    this.showReset = false;
    this.kartenItems.forEach((x) => {
      if (x.selected) {
        this.showReset = true;
      }
    });
  }

  public get hasSelected(): boolean {
    return this.kartenItems.find((value) => value.selected) != null;
  }

  public get hasOptions(): boolean {
    return this.kartenItems?.find((value) => value.active) != null;
  }
}
