import { NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subject, first, takeUntil } from 'rxjs';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScrollService } from 'src/app/services/scroll/scroll.service';
import { Studienangebot } from '../../../services/model/Studienangebot';
import { StudienangebotComponent } from './studienangebot-kachel/studienangebot.component';

@Component({
  selector: 'ba-studisu-kompakt-ansicht',
  templateUrl: './kompakt-ansicht.component.html',
  styleUrls: ['./kompakt-ansicht.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, StudienangebotComponent]
})
export class KompaktAnsichtComponent implements AfterViewInit, OnDestroy {
  @Input()
  studienabgebote: Studienangebot[];

  @Input()
  loading = true;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private navigationService: NavigationService,
    private scrollService: ScrollService,
    private elementRef: ElementRef
  ) {}

  ngAfterViewInit() {
    this.navigationService.letztesAngebot$
      .pipe(first(), takeUntil(this.ngUnsubscribe))
      .subscribe((id) => this.setzeFokusAuf(id));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private setzeFokusAuf(angebot: number) {
    const focus = () => {
      const kachel = this.elementRef.nativeElement.querySelector(`#angebot-${angebot}`);

      if (!!kachel) {
        // Fix für scrollverhalten siehe https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        const observer = new IntersectionObserver(([{ isIntersecting }]) => {
          if (isIntersecting) {
            this.navigationService.clearLetztesAngebot();
            (kachel?.querySelector('h2.titel a.ba-link-primary') as HTMLElement)?.focus();
            observer.disconnect();
          }
        });
        observer.observe(kachel);
      }
      this.scrollService.scrollIntoViewWithDelay(kachel);
    };
    setTimeout(focus, 300);
  }
}
