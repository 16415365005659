<div
  *ngIf="getAdditionalResultNum() > 0"
  class="ba-layout-tile py-2 d-print-none border border-1 border-blue mb-3"
>
  <div class="d-flex flex-wrap gap-2 align-items-center">
    <div class="ba-studisu-content">
      <span>{{ getAdditionalResultNum() | number }}</span>
      <span [ngPlural]="getAdditionalResultNum()">
        <ng-template ngPluralCase="=1"> Ergebnis durch Filter unterdrückt. </ng-template>
        <ng-template ngPluralCase="other"> Ergebnisse durch Filter unterdrückt. </ng-template>
      </span>
    </div>
    <button
      id="reset-all-facette-btn"
      class="ms-auto ba-btn ba-btn-tertiary ba-btn-icon ba-icon-x"
      (click)="resetFilters()"
    >
      <span>Filter löschen</span>
    </button>
    <div style="clear: both"></div>
  </div>
</div>
