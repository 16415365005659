import { Route, UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';

/**
 * Matcht alle UrlSegment, konsumiert dabei keine Segment aus der Liste
 */
const emptyMatcher: UrlMatcher = () => ({ consumed: [] });

/**
 * Entspricht dem RegEx ?, konsumiert Segmente aus der Liste nur, wenn sie gematcht wurden.
 */
export const optional: (UrlMatcher) => UrlMatcher = (matcher) => {
  return (url: UrlSegment[], group: UrlSegmentGroup, route: Route) => {
    return matcher(url, group, route) ?? emptyMatcher(url, group, route);
  };
};

/**
 * Kombiniert eine beliebige Anzahl an UrlMatchern, die sequentiell angewendet werden.
 */
export function joinMatcher(...matchers: UrlMatcher[]): UrlMatcher {
  return matchers.reduce(combineMatcher, emptyMatcher);
}

/**
 * Kombiniert zwei UrlMatcher, sodass sie sequentiell angewendet werden auf eine Liste von UrlSegmenten.
 */
function combineMatcher(matcherA: UrlMatcher, matcherB: UrlMatcher): UrlMatcher {
  return (url: UrlSegment[], group: UrlSegmentGroup, route: Route) => {
    const matchResultA = matcherA(url, group, route);
    if (matchResultA === null) {
      return null;
    }
    const urlRest = url.slice(matchResultA.consumed.length);
    const matchResultB: UrlMatchResult = matcherB(urlRest, group, route);
    if (matchResultB === null) {
      return null;
    }
    return {
      consumed: [...matchResultA.consumed, ...matchResultB.consumed],
      posParams: {
        ...matchResultA.posParams,
        ...matchResultB.posParams
      }
    };
  };
}
