import { AsyncPipe, KeyValuePipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  FacettenSlideInModule,
  ModalModule,
  ScreenSizeService,
  ViewMode
} from '@infosysbub/ng-lib-dpl3';
import { SearchResult } from '../../../model/SearchResult';
import { EventService } from '../../../services/event.service';
import { MessageService } from '../../../services/message.service';
import { FeedbackComponent } from '../../../ui-components/feedback/feedback.component';
import { UrlParamService } from '../services/url-param.service';
import { FacettenfilterComponent } from './facettenfilter/facettenfilter.component';
import { InfoPopupDelegete } from './facettenfilter/info-popup.delegete';
import { RegionenFacetteComponent } from './regionen-facette/regionen-facette.component';
import { StudienanbieterFacetteComponent } from './studienanbieter-facette/studienanbieter-facette.component';
import { SuchumkreisFacetteComponent } from './suchumkreis-facette/suchumkreis-facette.component';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'ba-studisu-suche-facettes',
  templateUrl: './suche-facettes.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    FeedbackComponent,
    FacettenSlideInModule,
    FacettenfilterComponent,
    RegionenFacetteComponent,
    SuchumkreisFacetteComponent,
    StudienanbieterFacetteComponent,
    NgFor,
    ModalModule,
    AsyncPipe,
    KeyValuePipe
  ]
})
export class SucheFacettesComponent {
  @Input()
  public facettenWerte: SearchResult;

  loading$ = this.eventService.studienangeboteSucheStatus;

  isNotDesktop$ = this.screenSizeService.rangeTo$(ViewMode.TABLET);
  isDesktop$ = this.screenSizeService.rangeFrom$(ViewMode.DESKTOP);

  // anbieten der constanten fuer das html template dieser component
  protected paramHochschulart = UrlParamService.PARAM_HOCHSCHULART;
  protected paramAbschlussgrad = UrlParamService.PARAM_ABSCHLUSSGRAD;
  protected paramStudienform = UrlParamService.PARAM_STUDIENFORMEN;
  protected paramStudientyp = UrlParamService.PARAM_STUDT;
  protected paramFFStudium = UrlParamService.PARAM_FFSTUDIUM;
  protected paramStudienmodell = UrlParamService.PARAM_STUDIENMODELL;

  public infopopupDelegate: InfoPopupDelegete;

  constructor(
    public messageService: MessageService,
    private screenSizeService: ScreenSizeService,
    private urlParamService: UrlParamService,
    private eventService: EventService
  ) {
    this.infopopupDelegate = new InfoPopupDelegete(messageService);
  }

  /**
   * lazy accessor for a info popup delegate for fit fuer studium
   * @returns {InfoPopupDelegete}
   */
  get fitFurStudiumInfoPopupDelegate(): InfoPopupDelegete {
    return new InfoPopupDelegete(this.messageService);
  }

  /**
   * Anzahl aller selektierten Filtern aller Facetten,
   * also wie viele Checkboxen sind insgesamt angewählt.
   */
  public filterCount() {
    return UrlParamService.FILTER_PARAMS.map(
      (param) => this.urlParamService.getParam(param) ?? ''
    ).reduce((totalCount, urlFilterValues) => {
      const filterValueCount = urlFilterValues
        .split(UrlParamService.VALUE_SEPARATOR)
        .filter((val) => val != '').length;
      return totalCount + filterValueCount;
    }, 0);
  }

  public setzeFilterZurueck() {
    this.urlParamService.resetFilter();
    this.urlParamService.updateView({ [UrlParamService.PARAM_PAGE]: 1 });
  }

  formatKey(s: string) {
    return s.replaceAll(' ', '-');
  }
}
