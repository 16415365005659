<ng-template #counter>
  <span class="wbsuche-lib-ergebniszaehler">{{ maximaleTreffer | number }}</span>
  {{ maximaleTreffer | i18nPlural: { '=1': 'Suchergebnis', other: 'Suchergebnisse' } }}
</ng-template>

<div>
  <div
    class="ba-layout-tile mb-3 pt-1 pt-md-3"
    id="suchtreffer"
    role="presentation"
    [ngClass]="{ 'd-none d-md-block': aktiveFilter?.length === 0 }"
  >
    <div class="d-none d-md-flex">
      <div class="w-100">
        <div aria-atomic="true" id="ergebniszaehler">
          <div class="d-flex">
            <h2 class="status-header h5">
              <span id="ergebniszaehler_text" aria-live="polite">
                <ng-container *ngTemplateOutlet="counter"></ng-container>
              </span>
            </h2>
            <span class="ms-auto d-none d-md-block">
              <a
                [routerLink]="'/merkliste'"
                queryParamsHandling="merge"
                class="ba-btn ba-btn-ghost ba-btn-red-ghost ba-btn-block pt-0"
                data-bs-toggle="popover"
                data-bs-placement="top"
                attr.data-bs-content="Merkliste aufrufen - {{
                  merklistCount$
                    | async
                    | i18nPlural
                      : {
                          '=0': 'keine Einträge',
                          '=1': '1 Eintrag',
                          other: '# Einträge'
                        }
                }}"
                id="zur-merkliste"
                #popoverElement
                (click)="popoverElement.bsPopover?.dispose()"
              >
                <ba-bub-icon-bookmark class="fs-5"></ba-bub-icon-bookmark>
                <span class="ms-1">Merkliste ({{ merklistCount$ | async }})</span></a
              >
            </span>
          </div>
          <div class="status-sub-header" *ngIf="maximaleTreffer > 20">
            <span *ngIf="maximaleTreffer > 20" id="paginierungoben_treffervonbis">
              Treffer {{ ersterTreffer | number }} bis {{ letzterTreffer | number }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <ba-bub-filter-chip-liste
      [aktiveFilter]="aktiveFilter"
      [showAnzahlOnlyOnDesktop]="true"
      (alleFilterEntfernt)="alleFilterLoeschen()"
      (filterEntfernt)="filterEntfernen($event)"
    ></ba-bub-filter-chip-liste>
  </div>

  <div class="d-print-none sortierung mb-0 mb-md-3" *ngIf="maximaleTreffer > 0">
    <div class="d-block d-md-none">
      <span id="ergebniszaehler_text_mobil" aria-live="polite" class="h5 mb-0">
        <ng-container *ngTemplateOutlet="counter"></ng-container>
      </span>
    </div>

    <button
      class="ba-btn ba-btn-secondary d-block d-lg-none ba-btn-icon ba-icon-filter"
      aria-label="Öffne Optionen zum Filtern"
      data-bs-toggle="modal"
      data-bs-target="#facetten-mobile"
      id="facetten-slide-out-btn"
    >
      Filtern
      <span *ngIf="aktiveFilter.length > 0">({{ aktiveFilter.length }})</span>
    </button>

    <!-- Paginierung oben -->
    <div class="d-print-none d-none d-lg-block" id="pagination_top">
      <ba-studisu-ergebnisliste-paginierung
        [anzahlTreffer]="maximaleTreffer"
        [aktuelleSeite]="currentPage"
        silkTestId="top"
      >
      </ba-studisu-ergebnisliste-paginierung>
    </div>

    <ba-bub-ansicht-switch
      class="d-none d-md-block ergebnisliste__obere-leiste-ansicht-switch"
      [initialState]="ansichtstypService.getAnsichtstyp$() | async"
      [showLabels]="true"
      (ansichtSelected)="ansichtstypService.setAnsichtstyp($event)"
    >
    </ba-bub-ansicht-switch>
  </div>
</div>
