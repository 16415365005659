import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DropdownModule } from '@infosysbub/ng-lib-dpl3';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { SearchResult } from '../../../../model/SearchResult';
import { Messages } from '../../../../ui-components/model/Messages';
import { UrlParamService } from '../../services/url-param.service';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'ba-studisu-ort-umkreis',
  templateUrl: './ort-suchumkreis.component.html',
  standalone: true,
  imports: [DropdownModule]
})
export class OrtSuchumkreisComponent implements OnInit, OnDestroy {
  private static FILTER_ID = UrlParamService.PARAM_UK;

  public suchumkreis: number;

  // Gibt an, ob diese Komponente aktiviert oder deaktivert ist.
  // Wird über Subscription an den Params geändert; wenn Ort-Param vorhanden ist, ist die Komponente aktiviert.
  // Auswertung von disabled und eigentliche Deaktivierung/Aktivierung erfolgt in der component.html / ba-studisu-dropdown.
  public disabled = true;

  public messages = Messages;
  public max = 500;

  //  Parameters can be overriden by parent component
  @Input()
  public range = ['50 km', '100 km', '150 km', '200 km', Messages.URLPARAM_UK_VALUE_BUNDESWEIT];

  @Input()
  private searchResult: SearchResult;

  private onParameterChangeSubscription: Subscription;

  /**
   * constructor
   * @param urlParamService (Injected) UrlParamService for triggering url updates
   */
  constructor(
    private urlParamService: UrlParamService,
    private hostRef: ElementRef
  ) {}

  /**
   * Initialization of this facette. Registers callback on url parameter change
   * which initializes default values. Subscribes on open facette event.
   */
  public ngOnInit() {
    this.onParameterChangeSubscription = this.urlParamService.currentParams
      .pipe(
        map((params) => {
          this.disabled = !params.has(UrlParamService.PARAM_ORTE); // disabled, wenn keine Orte vorhanden
          return params.get(OrtSuchumkreisComponent.FILTER_ID); // jetzt haben wir nur noch unseren Param
        }),
        distinctUntilChanged() // jetzt verarbeiten wir in nur, wenn er sich geändert hat
      )
      .subscribe((uk) => this.parseParameters(uk));
  }

  /**
   * OnDestroy Hook to unsubscribe made Subscriptions of this component
   */
  public ngOnDestroy() {
    if (this.onParameterChangeSubscription) {
      this.onParameterChangeSubscription.unsubscribe();
    }
  }

  /**
   * Liefert den Text für den Suchumkreis.
   */
  public getCurrentSelection() {
    return this.suchumkreis >= this.max
      ? Messages.URLPARAM_UK_VALUE_BUNDESWEIT
      : this.suchumkreis + ' km';
  }

  public onChange(value) {
    // update view because URL param does not contain selected value yet
    value = this.convertValue(value);
    this.updateSuchumkreisWithValue(
      +value >= this.max ? Messages.URLPARAM_UK_VALUE_BUNDESWEIT : value,
      true
    );
    return false;
  }

  /**
   * Convert value from dropdown-list (only strings)
   * @param value value from dropdown
   */
  private convertValue(value): string {
    if (value === Messages.URLPARAM_UK_VALUE_BUNDESWEIT) {
      return value;
    }
    return value.toString().substring(0, value.length - 3); // entferne ' km'
  }

  /**
   * Parser Function for URL Parameters. Will be called on url parameter change
   * @param params query params object
   */
  private parseParameters(uk: any) {
    // do not update view because URL param already contains selected value
    this.updateSuchumkreisWithValue(uk, false);
  }

  /**
   * Updates current selected value, registers it on the UrlParamService.
   *
   * WARNING writes history stacks, dont call me too often
   *
   * @param value new Value for the slider
   * @param updateView if the view has to be updated
   */
  private updateSuchumkreisWithValue(value: string, updateView: boolean) {
    if (value === Messages.URLPARAM_UK_VALUE_BUNDESWEIT) {
      this.suchumkreis = this.max;
    } else if (value && !isNaN(parseInt(value, 10))) {
      this.suchumkreis = Math.max(10, Math.min(+value, this.max));
    } else {
      this.suchumkreis = 50;
    }
    if (updateView) {
      this.urlParamService.updateView({
        [UrlParamService.PARAM_UK]: value,
        [UrlParamService.PARAM_PAGE]: 1
      });
    }
  }
}
