<ul *ngIf="news && news.length > 0" class="ba-linklist">
  <li *ngFor="let item of news">
    <a class="linklist-item ba-icon px-2" [routerLink]="'/news'">
      <div *ngIf="transformDate(item) as date">{{ date | date: 'dd.MM.yyyy' }}</div>
      <div role="heading" aria-level="3" class="fw-bold pb-1" *ngIf="item.titel as titel">
        {{ titel }}
      </div>
      <p *ngIf="item.text" [innerHTML]="stripHtml(item.text) | truncate: 3"></p>
    </a>
  </li>
</ul>
