<div
  *ngIf="loading || studienabgebote.length > 0"
  aria-label="Liste der Suchergebnisse"
  class="ba-result-list-results"
  id="ergebnisliste"
>
  <ba-studisu-studienangebot
    *ngFor="let studienangebot of studienabgebote"
    [studienangebot]="studienangebot"
  >
  </ba-studisu-studienangebot>
</div>
