import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { PipesModule } from '@infosysbub/ng-lib-dpl3';
import { News } from '../../../services/berufepool/model/news';

/**
 * Zeigt eine Vorschau auf eine News an.
 *
 * Vorlage: SPRAF
 */
@Component({
  selector: 'ba-studisu-news-teaser',
  templateUrl: './teaser.component.html',
  styleUrls: ['./teaser.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, RouterLink, DatePipe, PipesModule]
})
export class TeaserComponent {
  public transformDate = News.formatierungDate;

  @Input() public news: News[];

  public stripHtml(html) {
    return html?.replace(/<[^>]*>/g, '');
  }
}
