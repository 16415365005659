<div class="ba-layout-tile d-print-none">
  <div class="row gy-2">
    <div class="col-12 col-lg-7 position-relative">
      <div class="row">
        <div class="col-12 col-md-9 col-lg-8">
          <ba-studisu-textsuche [suchtyp]="suchtyp"></ba-studisu-textsuche>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-4">
          <ba-studisu-textsuche-typwahl
            (selectionChange)="onSuchtypChange($event)"
          ></ba-studisu-textsuche-typwahl>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 position-relative">
      <div class="row">
        <div class="col-12 col-md-9 col-lg-7">
          <ba-studisu-ort-suche></ba-studisu-ort-suche>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-5">
          <ba-studisu-ort-umkreis></ba-studisu-ort-umkreis>
        </div>
      </div>
    </div>
  </div>
</div>
