<ba-bub-typeahead
  id="Suche-Ort"
  label="{{ messages.UMKREIS_SUCHEINGABE_LABEL }}"
  iconClass="ba-icon studisu-suche-ort ba-icon-location-before"
  placeholder="{{ placeholderText }}"
  [loadSuggestions]="loadSuggestions"
  [disable]="disabled"
  (suggestionSelected)="onOrtSelected($event)"
>
</ba-bub-typeahead>

<ba-bub-tagcloud
  *ngIf="tagCloudItems.length > 0"
  id="orte-tagcloud"
  [tagListeAriaLabel]=""
  [headline]="headline() + ':'"
  [removeAllTooltip]="'Alle gewählten Orte entfernen'"
  [tagcloudListe]="tagCloudItems"
  [maxTagsVisible]="3"
  [ariaLabel]="'Weitere Orte'"
  [ariaLabelContent]="'Zusätzliche Orte'"
  [labelForOpen]="'Gewählte Orte anzeigen'"
  [labelForClose]="'Gewählte Orte ausblenden'"
  (tagcloudListeRemove)="removeItem($event)"
  (tagcloudListeRemoveAll)="removeAll()"
>
</ba-bub-tagcloud>
