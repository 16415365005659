import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { StudisuConfig, getStudisuConfig } from '../../../config/studisu.config';
import { checkParams } from '../../../utils/check-params.util';
import { Ort } from './model/Ort';

@Injectable()
export class OrtService {
  private conf: StudisuConfig = getStudisuConfig();

  constructor(private http: HttpClient) {}

  public getOrtBySuchwort(searchTerm: string, orte: Ort[]): Observable<Ort[]> {
    let urlParams = new HttpParams();
    const selektierteOrte = orte
      .map((ort) => {
        const ortString = ort.postleitzahl ? String(ort.postleitzahl) : ort.name.toLowerCase();
        return encodeURIComponent(ortString);
      })
      .join(',');
    if (searchTerm) {
      urlParams = new HttpParams().set('ortsuche', searchTerm).set('selektiert', selektierteOrte);
    }

    return this.getOrteBy(urlParams);
  }

  private getOrteBy(urlParams: HttpParams): Observable<Array<Ort>> {
    return this.http
      .get<Array<Ort>>(`${this.conf.studienangeboteServiceHost}/pc/v1/orte`, {
        params: checkParams(urlParams),
        responseType: 'json'
      })
      .pipe(
        catchError((error) => {
          throw new Error(error);
        })
      );
  }
}
