import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaginierungDplModule } from '@infosysbub/ng-lib-dpl3';
import { UrlParamService } from '../../../services/url-param.service';

@Component({
  selector: 'ba-studisu-ergebnisliste-paginierung',
  templateUrl: './paginierung.component.html',
  standalone: true,
  imports: [PaginierungDplModule]
})
export class PaginierungComponent implements OnInit {
  public paginationUnten: number[];
  @Input() public anzahlTreffer: number;
  @Input() public aktuelleSeite: number;
  @Input() public silkTestId: 'top' | 'bottom';
  @Output() public angeboteLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private urlParamService: UrlParamService) {}

  public ngOnInit() {
    this.paginationUnten = this.berechnePaginierung();
  }

  public get aktuellePaginierung() {
    return (this.paginationUnten = this.berechnePaginierung());
  }

  public loadAngeboteCertainPage(pg = 1) {
    if (pg < 1 || pg > this.anzahlSeiten || pg === this.aktuelleSeite) {
      return;
    }
    this.urlParamService.updateView({ [UrlParamService.PARAM_PAGE]: pg }, { replaceUrl: true });

    this.angeboteLoaded.emit(true);
  }

  public get anzahlSeiten(): number {
    return Math.min(Math.ceil(this.anzahlTreffer / 20), 500);
  }

  private berechnePaginierung() {
    let page = this.aktuelleSeite;
    let paginationDetails: number[] = [];

    if (this.anzahlSeiten <= 7) {
      for (let i = 2; i <= this.anzahlSeiten - 1; i++) {
        paginationDetails.push(i);
      }
    } else {
      if (page <= 3) {
        paginationDetails.push(2);
        paginationDetails.push(3);
        if (page === 3) {
          paginationDetails.push(4);
        }
      } else if (page >= this.anzahlSeiten - 2) {
        if (page === this.anzahlSeiten - 2) {
          paginationDetails.push(this.anzahlSeiten - 3);
        }
        paginationDetails.push(this.anzahlSeiten - 2);
        paginationDetails.push(this.anzahlSeiten - 1);
      } else {
        paginationDetails.push(page - 1);
        paginationDetails.push(page);
        paginationDetails.push(page + 1);
      }
    }

    return paginationDetails;
  }
}
