import { AsyncPipe, NgIf, formatNumber } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  LoadSuggestions,
  SelectedSuggestion,
  SuggestionRenderer,
  TagItem,
  TagcloudModule,
  TypeaheadComponent,
  TypeaheadModule
} from '@infosysbub/ng-lib-dpl3';
import { ReplaySubject, forkJoin, of as observableOf } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Messages } from '../../../../ui-components/model/Messages';
import { SearchType } from '../../../../ui-components/model/SelectItem';
import { Studienfeld } from '../../../studienfelder/services/model/Studienfeld';
import { StudienfelderService } from '../../../studienfelder/services/studienfelder.service';
import { StudienfelderDelegate } from '../../../studienfelder/studienfelder.delegate';
import { ISelectItem } from '../../services/model/ISelectItem';
import { Studienfach } from '../../services/model/Studienfach';
import { Suchtyp } from '../../services/model/Suchtyp';
import { Suchwort } from '../../services/model/Suchwort';
import { Teaser } from '../../services/model/Teaser';
import { StudienfachService } from '../../services/studienfach.service';
import { SuchworteDelegate } from '../../services/suchworte.delegate';
import { SuchworteService } from '../../services/suchworte.service';
import { UrlParamService } from '../../services/url-param.service';

/**
 * Diese Komponente ist für die Auswahl von Suchworten/Angebotstiteln zuständig.
 *
 * Sind bereits ein oder mehrere Suchworte gewaehlt, verhaelt sich das
 * zugeordnete weitere Suchwort wie eine ODER- Verknuepfung.
 */
@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    SuchworteService,
    SuchworteDelegate,
    StudienfachService,
    StudienfelderService,
    StudienfelderDelegate
  ],
  styleUrls: ['textsuche.component.scss'],
  selector: 'ba-studisu-textsuche',
  templateUrl: './textsuche.component.html',
  // Encapsulation aufgehoben, um Elemente der Vorschlagsliste,
  // die im Typeahead gekapselt sind, durchzuschlagen.
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TypeaheadModule, NgIf, TagcloudModule, AsyncPipe]
})
export class TextsucheComponent implements OnInit, OnChanges, OnDestroy {
  public static AUTOSUGGEST_MINLEN = 2;

  @Input()
  public hasSearchResult: boolean;

  @Input()
  public suchtyp: Suchtyp = null;

  /**
   * Text, der unterm Eingabefeld erscheint, falls nur ein Ort ausgewählt wurde
   */
  public studienfachNichtVorhanden = Messages.FEHLER_STUDIENFELD_FEHLT;
  public messages = Messages;
  /**
   * Tooltip für den "Alle Löschen" Link
   */
  public deleteAllTooltip = Messages.STUDIENBEREICHE_ALLE_LOESCHEN_TOOLTIP;
  @ViewChild('typeahead')
  public typeaheadComponent: TypeaheadComponent;
  public tagCloudItems$: ReplaySubject<TagItem[]> = new ReplaySubject();
  private suchDelegate: StudienfelderDelegate | SuchworteDelegate = null;

  constructor(
    private urlParamService: UrlParamService,
    public studienfelderDelegate: StudienfelderDelegate,
    public suchworteDelegate: SuchworteDelegate,
    private sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef
  ) {}

  /**
   * Überschrift für die Tagcloud
   */
  public get gewaehlteStudienbereicheLabel() {
    return this.tagCloudItems.length > 1
      ? Messages.GEWAEHLTE_STUDIENBEREICHE
      : Messages.GEWAEHLTER_STUDIENBEREICH;
  }

  get tagCloudItems(): ISelectItem[] {
    return [
      ...this.selektierteSuchworte,
      ...this.selektierteStudienfelder,
      ...this.selektierteStudienfaecher
    ];
  }

  public updateTagCloudItems(): void {
    const joinedItems = forkJoin(
      [
        ...this.selektierteSuchworte,
        ...this.selektierteStudienfelder,
        ...this.selektierteStudienfaecher
      ].map((item) => this.tagItem(item))
    );
    joinedItems.subscribe((items) => {
      this.tagCloudItems$.next(items);
      this.ref.detectChanges();
    });
  }

  get selektierteStudienfaecher() {
    return this.studienfelderDelegate.selektierteStudienfaecher;
  }

  get selektierteStudienfelder() {
    return this.studienfelderDelegate.selektierteStudienfelder;
  }

  get selektierteSuchworte() {
    return this.suchworteDelegate.selektierteSuchworte;
  }

  get isMaxParameterCountExceeded(): boolean {
    return this.suchDelegate.isMaxParameterCountExceeded;
  }

  /**
   * Initialisierung der Komponente
   *
   * Hier wird der zugehoerige URL-Parameter ausgelesen und verarbeitet
   */
  public ngOnInit() {
    this.studienfelderDelegate.isReady.subscribe((_) => this.updateTagCloudItems());
    this.suchworteDelegate.isReady.subscribe((_) => this.updateTagCloudItems());
    this.studienfelderDelegate.init();
    this.suchworteDelegate.init();
  }

  /**
   * Wird aufgerufen, wenn sich die Input()-Parameter ändern.
   */
  public ngOnChanges() {
    // Wenn sich der Suchtyp ändert, ändert sich das Verhalten der Suche (initial: Suchwort).
    this.updateTagCloudItems();
    this.suchDelegate =
      this.suchtyp === null || this.suchtyp.type === SearchType.suchwort
        ? this.suchworteDelegate
        : this.studienfelderDelegate;
    if (this.typeaheadComponent) {
      this.typeaheadComponent.reload();
    }
  }

  public ngOnDestroy() {
    this.studienfelderDelegate.destroy();
    this.suchworteDelegate.destroy();
  }

  /**
   * Lade-Funktion fuer die Auto-Complete-Komponente.
   *
   * Achtung! Die Funktionsdefinition mit "=" und "=>" sorgt dafür, dass der "this"-Kontext bei der
   * Übergabe der Methode als Eingabeparameter für die Autocomplete-Komponente erhalten bleibt.
   *
   * *NICHT* ändern!
   *
   * @param {string} suchText Der eingebene Suchtext
   * @returns {SelectItem[]} die Auto-Complete-Verschlaege
   */
  public loadSuggestions: LoadSuggestions = (suchText: string) => {
    if (this.suchDelegate == null) {
      return observableOf([]);
    }
    return this.suchDelegate.loadSuggestions(suchText, this.suchtyp);
  };

  /*
    Der Standard-SuggestionRenderer der Typeahead-Komponente rendert lediglich das jeweilige Label der Suggestions.
    Wir wollen hier aber mehr anzeigen. Deswegen schreiben wir hier unseren eigenen Renderer.
    Die Schnittstelle liefert uns dazu (a) die Suggestion, die gerendert werden soll, (b) den Text der eingegeben wurde
    und (c) einen Highlighter um den eingegebenen Text im HTML fett darstellen zu können.
    (Das Ergebnis landet dann in einem A-Tag in einem LI.)
   */
  public suggestionRenderer: SuggestionRenderer = (suggestion, searchInput, highlighter) => {
    const unit = suggestion.value.treffer === 1 ? 'Studiengang' : 'Studiengänge';
    const prefix = this.tagCloudItems.length > 0 ? '+' : '';

    const label = `<div class="overflow-wrap-anywhere">${highlighter(
      suggestion.label,
      searchInput
    )}</div>`;
    const count = `<div class="ms-auto flex-shrink-0 count-label ps-2">(${prefix}${formatNumber(
      suggestion.value.treffer,
      'de'
    )} ${unit})</div>`;
    let zusatz = '';
    if (suggestion.value.anbietername) {
      zusatz = `<div class="studisu-autocomplete-anbietername">${suggestion.value.anbietername}</div>`;
    }
    return this.sanitizer.bypassSecurityTrustHtml(
      `<div class="position-relative w-100"><div class="d-flex">${label}${count}</div><div>${zusatz}</div></div>`
    );
  };

  /**
   * Nimmt die bestätigte Eingabe der Suchauswahl entgegen.
   *
   * Verwendet das zum Suchtyp passende Delegate, um die Auswahl in die Liste des Delegates zu übernehmen und
   * die URL entsprechend anzupassen.
   *
   * @param suchAuswahl neu eingegebenes Suchwort.
   */
  public onSuggestionSelected(suchAuswahl: SelectedSuggestion): void {
    if (this.suchDelegate != null) {
      this.suchDelegate.addAuswahl(suchAuswahl);
    }
    this.updateTagCloudItems();
  }

  /**
   * Überprüft, ob im URL Parameter 'ort' enthalten ist
   */
  public checkForOrtParam() {
    let urlParams: HttpParams = this.urlParamService.getSearchParams();
    if (
      urlParams.has(UrlParamService.PARAM_ORTE) &&
      !urlParams.has(UrlParamService.PARAM_STUDIENFAECHER) &&
      !urlParams.has(UrlParamService.PARAM_STUDIENFELDER) &&
      !urlParams.has(UrlParamService.PARAM_SUCHWORTE) &&
      !urlParams.has(UrlParamService.PARAM_AUTOCOMPLETE)
    ) {
      return true;
    }
  }

  /**
   * Setzt die Suche bezüglich Suchworten, Studienfeldern und Studienfächern sowie gewaehlter Facetten vollständig zurück.
   * Behalten werden nur die Suchworte für Regionen.
   */
  public bereinigeStudienbereicheUndSuchworteFilter() {
    this.studienfelderDelegate.bereinigeStudienbereicheFilter();
    this.suchworteDelegate.bereinigeSuchworteFilter();
  }

  /**
   * De-selektiert ein Suchwort, Studienfeld oder Studienfach.
   *
   * @param item Das zu entfernende Item.
   */
  public remove(tagItem: TagItem) {
    const item = tagItem.value;
    if (item instanceof Studienfach || item instanceof Studienfeld) {
      this.studienfelderDelegate.remove(item);
    } else if (item instanceof Suchwort) {
      this.suchworteDelegate.remove(item);
    }
    if (this.typeaheadComponent) {
      this.typeaheadComponent.focus();
    }
    this.updateTagCloudItems();
  }

  /**
   * Text, der im disableten Eingabefeld der Suchwortauswahl erscheint
   */
  public placeholderDisabledText(): string {
    if (this.suchDelegate == null) {
      return '';
    }
    return this.suchDelegate.maxParameterCountExceededErrorMessage;
  }

  private tagItem(entity: Suchwort | Studienfach | Studienfeld): Observable<TagItem> {
    return this.studienfelderDelegate.generateLink(entity).pipe(
      map((link) => {
        const tagItem = {
          id: entity.key,
          value: entity,
          angezeigterText: entity.label,
          icon: `ba-icon-${entity.icon}`,
          teaser: undefined
        };

        if (entity instanceof Studienfach && (entity as Studienfach).teaser) {
          const teaser = (entity as Studienfach).teaser;
          const text = this.buildTeaserText(link, teaser);
          tagItem.teaser = {
            imageUrl: teaser.bild ?? 'assets/images/platzhalterbild.png',
            text,
            title: entity.label
          };
        }
        return tagItem;
      })
    );
  }

  private buildTeaserText(link: string, teaser: Teaser): SafeHtml {
    // Abschnitte in Überschrift + Content wrappen
    const wrap = (title, html) => (html != null ? `<h4 class="h6">${title}</h4>` + html : '');
    // Abschnitte zusammenfügen
    const merge = (...v) => v.join('');

    const _link = `<a href='${link}' referrerpolicy="same-origin">Mehr zum Studienfach</a>`;
    const _studienfachbezeichnung = wrap('', teaser.studienBeschreibung);
    const _studiendauer = wrap('Studiendauer:', teaser.studiendauer);
    const _berufstaetigkeiten = wrap(
      'Typische Berufstätigkeit nach dem Studium:',
      teaser.taetigkeitsBeschreibung
    );

    return this.sanitizer.bypassSecurityTrustHtml(
      merge(_studienfachbezeichnung, _link, _studiendauer, _berufstaetigkeiten)
    );
  }
}
