<ba-bub-pagination-dpl
  [anzahlTreffer]="anzahlSeiten"
  [aktuelleSeite]="aktuelleSeite"
  [anzahlSeiten]="anzahlSeiten"
  [position]="silkTestId"
  (firstClicked)="loadAngeboteCertainPage(1)"
  (prevClicked)="loadAngeboteCertainPage(aktuelleSeite - 1)"
  (nextClicked)="loadAngeboteCertainPage(aktuelleSeite + 1)"
  (threePagesPreviousClicked)="loadAngeboteCertainPage(aktuelleSeite - 3)"
  (threePagesNextClicked)="loadAngeboteCertainPage(aktuelleSeite + 3)"
  (lastClicked)="loadAngeboteCertainPage(anzahlSeiten)"
></ba-bub-pagination-dpl>
