import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable, Provider, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SHOW_MODAL_ON_ERROR } from './interceptor.token';
import { StudisuErrorService } from './studisuerror.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private errorService = inject(StudisuErrorService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(SHOW_MODAL_ON_ERROR)) {
      return this.handleError(next, request);
    }
    return next.handle(request);
  }

  private handleError(
    next: HttpHandler,
    request: HttpRequest<unknown>
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Im Falle von HttpStatusCode.Unauthorized oder Forbidden wird der BaHttpSecurityInterceptorService
        // ein Retry auslösen, da die Ursache für diesen Status ein abgelaufenes OAG-Token ist,
        // welches dabei vom BaHttpSecurityInterceptorService erneuert wird.
        if (
          error.status !== HttpStatusCode.Unauthorized &&
          error.status !== HttpStatusCode.Forbidden &&
          error.status !== HttpStatusCode.NotFound
        ) {
          this.errorService.pushError(error.message, error);
        }
        return throwError(() => error);
      })
    );
  }
}

export function provideErrorInterceptor(): Provider {
  return {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
    deps: [StudisuErrorService]
  };
}
