import { HttpParams } from '@angular/common/http';

export function checkParams(urlParams: HttpParams): HttpParams {
  urlParams.keys().forEach((key) => {
    const param = urlParams.get(key);
    if (!param || param === 'undefined' || param === '') {
      urlParams = urlParams.delete(key);
    }
  });
  return urlParams;
}
