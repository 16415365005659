<ba-bub-modal-popup
  #modalPopup
  modalId="modalHinweisDialog"
  [modalTitle]="modalData?.title"
  [modalSubtitle]="modalData?.subtitle"
  icon="info"
  primaryButtonText="Schließen"
  secondaryButtonText=""
  ><p *ngIf="modalData?.text" [innerHtml]="modalData.text"></p>
</ba-bub-modal-popup>
