<table
  *ngIf="studienabgebote"
  class="ba-table studisu-table shadow-sm rounded bg-white my-lg-3"
  aria-label="Tabelle Suchergebnisse"
>
  <thead>
    <tr>
      <th>Angebotstitel</th>
      <th>Anbieter</th>
      <th>Ort</th>
      <th aria-hidden="true"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let studienangebot of studienabgebote">
      <tr class="studisu-row" (click)="zeigeDetailseite(studienangebot.id)">
        <td class="studisu-cell lh-1">
          <a
            id="angebot_{{ studienangebot.id }}_link"
            routerLink="/studienangebot/{{ studienangebot.id }}"
            [queryParams]="getStudienangebotQueryParams()"
            [innerHTML]="breakUpLongWords(studienangebot.studiBezeichnung)"
          ></a>
        </td>
        <td class="studisu-cell">
          {{ studienangebot.bildungsanbieterName }}
        </td>
        <td class="studisu-cell">
          <i aria-hidden="true" class="d-sm-none ba-icon ba-icon-location-full-before"></i>
          <span>{{ studienangebot?.studienort?.ort || studienangebot?.ohneStudienortText }}</span>
        </td>
        <td class="studisu-cell arrow-cell">
          <ba-bub-bookmark
            class="d-none d-md-block"
            [id]="studienangebot.id"
            [title]="studienangebot.studiBezeichnung"
            [large]="true"
            [gemerkteIds]="merklistService.getMerklist() | async"
            (aufMerklisteHinzufuegen)="merklistService.addToMerklist(studienangebot.id)"
            (vonMerklisteEntfernen)="merklistService.removeFromMerklist(studienangebot.id)"
          ></ba-bub-bookmark>
          <i class="ba-icon ba-icon-arrow-right-after d-sm-none"></i>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
