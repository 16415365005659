import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BookmarkModule } from '@infosysbub/ng-lib-dpl3';
import { Subject, first, takeUntil } from 'rxjs';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScrollService } from 'src/app/services/scroll/scroll.service';
import { MerklistService } from '../../../../../services/merklist/merklist.service';
import { Studienangebot } from '../../../services/model/Studienangebot';
import { UrlParamService } from '../../../services/url-param.service';

@Component({
  selector: 'ba-studisu-listen-ansicht',
  templateUrl: './listen-ansicht.component.html',
  styleUrls: ['./listen-ansicht.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, RouterLink, BookmarkModule, AsyncPipe]
})
export class ListenAnsichtComponent implements AfterViewInit, OnDestroy {
  @Input()
  studienabgebote: Studienangebot[];

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private urlService: UrlParamService,
    private elementRef: ElementRef,
    public merklistService: MerklistService,
    private scrollService: ScrollService,
    private navigationService: NavigationService
  ) {}

  ngAfterViewInit() {
    this.navigationService.letztesAngebot$
      .pipe(first(), takeUntil(this.ngUnsubscribe))
      .subscribe((id) => this.setzeFokusAuf(id));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // private setzeFokusAuf(angebot: number) {
  //   setTimeout(() => {
  //     const titleLink: HTMLLinkElement = this.elementRef.nativeElement.querySelector(
  //       `#angebot_${angebot}_link`
  //     );
  //     this.scrollService.scrollIntoViewAndFocus(titleLink);
  //     this.navigationService.clearLetztesAngebot();
  //   }, 5);
  // }

  private setzeFokusAuf(angebot: number) {
    const focus = () => {
      const kachel = this.elementRef.nativeElement.querySelector(`#angebot_${angebot}_link`);

      if (!!kachel) {
        // Fix für scrollverhalten siehe https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        const observer = new IntersectionObserver(([{ isIntersecting }]) => {
          if (isIntersecting) {
            this.navigationService.clearLetztesAngebot();
            kachel.focus();
            observer.disconnect();
          }
        });
        observer.observe(kachel);
      }
      this.scrollService.scrollIntoViewWithDelay(kachel);
    };
    setTimeout(focus, 300);
  }

  public getStudienangebotQueryParams(): {} {
    return this.urlService.getQueryParams();
  }

  /**
   * Fügt manuelle Umbrüche nach Schrägstrichen ein.
   */
  breakUpLongWords(word: string): string {
    return word.replace(new RegExp('/', 'g'), '/<wbr>');
  }

  zeigeDetailseite(id: string) {
    this.urlService.navigateTo('/studienangebot/' + id);
  }
}
