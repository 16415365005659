import { ISelectItem } from './ISelectItem';

/**
 * Representation eines Suchwortes.
 */
export class Suchwort implements ISelectItem {
  constructor(
    public _key?: string,
    public name?: string,
    public treffer?: number,
    public anbietername?: string
  ) {
    this._tooltip = '';
  }

  _tooltip: string;

  get tooltip(): string {
    return this._tooltip;
  }

  get key(): string {
    if (typeof this._key === 'undefined') {
      this._key = '' + this.name;
    }
    return this._key;
  }

  get label(): string {
    return this.name;
  }

  get value(): string {
    return this.name;
  }

  get icon(): string {
    return 'search';
  }

  get clickable(): boolean {
    return false;
  }
}
