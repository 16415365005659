import { Facette } from '../main/suche/services/model/Facetten';

/**
 * AggregationResult returned by our REST-API studienangebot
 *
 * using optional parameters only
 * as in error scenarios we want to return empty results which is easier this way
 */
export class FacettenResult {
  constructor(
    public facetten: Facette[] = [],
    public maxErgebnisse = 0, // value is not capped at 10000 Results
    public filteredOutErgebnisse = 0
  ) {}
}
