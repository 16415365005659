<!-- desktop -->
<ng-container *ngIf="(isNotDesktop$ | async) === false; else facetten_mobile">
  <ng-container *ngTemplateOutlet="filter"></ng-container>
  <div class="ba-layout-tile" *ngIf="isDesktop$ | async">
    <ba-studisu-feedback></ba-studisu-feedback>
  </div>
</ng-container>

<!-- mobile -->
<ng-template #facetten_mobile>
  <ba-bub-facetten-slide-in
    [id]="'facetten-mobile'"
    [title]="'Filtern (' + filterCount() + ')'"
    [loading]="(loading$ | async) === true"
    [anzahlSuchergebnis]="facettenWerte.maxErgebnisse"
    (resetAllFilters)="setzeFilterZurueck()"
  >
    <ng-container *ngTemplateOutlet="filter"></ng-container>
  </ba-bub-facetten-slide-in>
</ng-template>

<!-- Filter Inhalte -->
<ng-template #filter>
  <div *ngIf="facettenWerte" role="form">
    <h2 class="sr-only" *ngIf="isDesktop$ | async">Filtern</h2>
    <ba-studisu-facettenfilter
      filterFacettenName="Studientyp"
      icon="studientyp"
      [showMobile]="isNotDesktop$ | async"
      [facettenWerte]="facettenWerte"
      [urlParam]="paramStudientyp"
    ></ba-studisu-facettenfilter>
    <ba-studisu-regionen-facette
      [searchResult]="facettenWerte"
      [showMobile]="isNotDesktop$ | async"
    ></ba-studisu-regionen-facette>
    <ba-studisu-umkreis-facette
      icon="location"
      [searchResult]="facettenWerte"
      [showMobile]="isNotDesktop$ | async"
    ></ba-studisu-umkreis-facette>
    <ba-studisu-facettenfilter
      filterFacettenName="Studienform"
      icon="time"
      [facettenWerte]="facettenWerte"
      [urlParam]="paramStudienform"
      [showMobile]="isNotDesktop$ | async"
    ></ba-studisu-facettenfilter>
    <ba-studisu-facettenfilter
      filterFacettenName="Duales Studienmodell"
      icon="duales-studium"
      [showInfo]="true"
      [facettenWerte]="facettenWerte"
      [urlParam]="paramStudienmodell"
      [showMobile]="isNotDesktop$ | async"
    ></ba-studisu-facettenfilter>
    <ba-studisu-facettenfilter
      filterFacettenName="Abschlussgrad"
      icon="certificate"
      [facettenWerte]="facettenWerte"
      [urlParam]="paramAbschlussgrad"
      [showMobile]="isNotDesktop$ | async"
    ></ba-studisu-facettenfilter>
    <ba-studisu-facettenfilter
      filterFacettenName="Hochschulart"
      icon="hochschulart"
      [facettenWerte]="facettenWerte"
      [urlParam]="paramHochschulart"
      [showMobile]="isNotDesktop$ | async"
    ></ba-studisu-facettenfilter>
    <ba-studisu-studienanbieter-facette
      [facettenWerte]="facettenWerte"
      [showMobile]="isNotDesktop$ | async"
    ></ba-studisu-studienanbieter-facette>
    <!-- STUDISU-367 TestURL /suche?sfe=94114;93999 -->
    <ba-studisu-facettenfilter
      filterFacettenName="Eignungstest"
      icon="eignungstest"
      styleClass="ba-filterfacet-ffstudium"
      [showInfo]="true"
      [facettenWerte]="facettenWerte"
      [urlParam]="paramFFStudium"
      [showMobile]="isNotDesktop$ | async"
    ></ba-studisu-facettenfilter>
  </div>
</ng-template>

<ba-bub-modal-popup
  *ngFor="let entity of infopopupDelegate.getInfo() | keyvalue"
  [modalId]="formatKey(entity.key)"
  [modalTitle]="entity.value.title"
  [icon]="'info'"
  [primaryButtonText]="'Schließen'"
  secondaryButtonText=""
  [ariaLabel]="'Informationen zu ' + entity.value.title"
>
  <p>{{ entity.value.text }}</p>
</ba-bub-modal-popup>
