import { Injectable } from '@angular/core';
import { AnsichtsTypen, ScreenSizeService, ViewMode } from '@infosysbub/ng-lib-dpl3';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlParamService } from '../main/suche/services/url-param.service';

/**
 * Bestimmt den Ansichtstyp anhand von
 *   - URL-Parameter
 *   - Aktueller Breite des Viewports
 *
 * Bei einer Breite des Viewports unter 768px (vgl. PDS), wird _immer_
 * die Listenansicht angezeigt. Es kann nicht auf die Kompaktansicht gewechselt werden.
 * Ansonsten bestimmt der URL-Parameter die Ansicht;
 */
@Injectable()
export class AnsichtstypService {
  private ansichtstyp$: BehaviorSubject<AnsichtsTypen>;

  constructor(
    private urlParamService: UrlParamService,
    private screenSizeService: ScreenSizeService
  ) {
    const initialValue =
      this.urlParamService.getParam(UrlParamService.PARAM_ANSICHT)?.toUpperCase() ==
      AnsichtsTypen.LISTE
        ? AnsichtsTypen.LISTE
        : AnsichtsTypen.KOMPAKT;
    this.ansichtstyp$ = new BehaviorSubject<AnsichtsTypen>(initialValue);
    this.ansichtstyp$.subscribe((v) =>
      this.urlParamService.updateView(
        { [UrlParamService.PARAM_ANSICHT]: v.toLowerCase() },
        { replaceUrl: true }
      )
    );
  }

  setAnsichtstyp(typ: AnsichtsTypen) {
    this.ansichtstyp$.next(typ);
  }

  getAnsichtstyp$(): Observable<AnsichtsTypen> {
    return combineLatest([
      this.ansichtstyp$,
      this.screenSizeService.rangeTo$(ViewMode.PHABLET)
    ]).pipe(map(([typ, isSmall]) => (isSmall ? AnsichtsTypen.LISTE : typ)));
  }
}
