<div id="suchergebnis" [ngClass]="loading ? 'ba-loading' : 'faded-out'">
  <div class="mb-3 mb-md-0">
    <div class="_studiensuche-ergebnis_" *ngIf="searchResult && facettenWerte">
      <div
        role="region"
        [attr.aria-label]="
          'Ergebnisliste (' +
          searchResult.items.length +
          ' von ' +
          facettenWerte.maxErgebnisse +
          ')'
        "
        [attr.aria-busy]="loading ? 'true' : 'false'"
      >
        <div id="ergebniszaehler" class="_ergebniszaehler_">
          <div *ngIf="searchResult.hasSearched">
            <ba-studisu-ergebnisliste-header
              [maximaleTreffer]="facettenWerte.maxErgebnisse"
              [ersterTreffer]="erstesErgebnis"
              [letzterTreffer]="letztesErgebnis"
              [currentPage]="currentPage"
              [facetten]="facettenWerte.facetten"
            >
            </ba-studisu-ergebnisliste-header>
            <div
              id="keinePassendenErgebnisse"
              *ngIf="isPageValid && searchResult.items.length === 0"
            >
              <ba-bub-suchergebnis-empty-state></ba-bub-suchergebnis-empty-state>
            </div>
          </div>
          <div *ngIf="searchResult.items.length === 0 && !searchResult.hasSearched">
            <h2
              aria-atomic="true"
              class="__anzahl_"
              id="ergebniszaehlerOhneErgebnis"
              [innerHTML]="keineSuchkriterienAngegeben"
              aria-live="polite"
            ></h2>
            <div id="mindestensStudienbereichErforderlich" [innerHTML]="" class="mb-3">
              <p>{{ mindestensStudienbereichErforderlich }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="hinweis_max_ergebnisse_top"
    *ngIf="showMaxAlert"
    class="alert alert-info d-print-none"
    role="note"
    aria-label="Hinweis"
  >
    Es können nur 10.000 Treffer angezeigt werden. Für weitere Treffer ist die Suche einzugrenzen
    oder zu filtern.
  </div>

  <div class="_studiensuche-ergebnis_" *ngIf="searchResult?.items?.length > 0">
    <div
      role="region"
      [attr.aria-label]="
        'Ergebnisliste (' + searchResult.items.length + ' von ' + facettenWerte?.maxErgebnisse + ')'
      "
      [attr.aria-busy]="loading"
    >
      <ng-container [ngSwitch]="ansichtstypService.getAnsichtstyp$() | async">
        <ba-studisu-kompakt-ansicht
          *ngSwitchCase="'KOMPAKT'"
          [loading]="loading"
          [studienabgebote]="searchResult.items"
        >
        </ba-studisu-kompakt-ansicht>

        <ba-studisu-listen-ansicht
          *ngSwitchCase="'LISTE'"
          [studienabgebote]="searchResult.items"
        ></ba-studisu-listen-ansicht>
      </ng-container>
    </div>
  </div>

  <div
    id="hinweis_max_ergebnisse_bottom"
    *ngIf="showMaxAlert"
    class="alert alert-info d-print-none"
    role="note"
    aria-label="Hinweis"
  >
    Es können nur 10.000 Treffer angezeigt werden. Für weitere Treffer ist die Suche einzugrenzen
    oder zu filtern.
  </div>

  <div class="mb-4 d-print-none" id="pagination_bottom">
    <ba-studisu-ergebnisliste-paginierung
      [anzahlTreffer]="facettenWerte?.maxErgebnisse"
      [aktuelleSeite]="currentPage"
      (angeboteLoaded)="fokusOnTop()"
      silkTestId="bottom"
    >
    </ba-studisu-ergebnisliste-paginierung>
  </div>
  <div class="ba-layout-tile" *ngIf="isNotDesktop$ | async">
    <ba-studisu-feedback></ba-studisu-feedback>
  </div>
</div>
