<ba-bub-facette
  [id]="erzeugeId()"
  icon="{{ icon ? ' ba-icon ba-icon-' + icon + '-before studisu-icon-' + icon + '-before' : '' }}"
  facettenName="{{ filterFacettenName }}"
  [showReset]="showReset"
  (resetClicked)="resetFilters()"
  [facettenInhalt]="facettenInhalt"
  [druckansicht]="druckansicht"
  *ngIf="!showMobile && isFilterFacetteVisible()"
  [headingLevel]="3"
></ba-bub-facette>

<ng-template #facettenInhalt>
  <div id="{{ filterFacettenName | sgNormanizeId }}Content">
    <ba-bub-checkbox-liste
      [id]="erzeugeId()"
      [filterList]="convertToFilterSelectItemList()"
      (checkboxClicked)="onFilterFacetteAendern($event)"
    ></ba-bub-checkbox-liste>
    <div
      id="{{ filterFacettenName | sgNormanizeId }}_keineErgebnisse"
      *ngIf="!filterList || filterList.length === 0"
      [innerHTML]="keineErgebnisse"
    ></div>
  </div>
</ng-template>

<ng-template #druckansicht>
  <div id="{{ filterFacettenName | sgNormanizeId }}ContentPrint">
    <div>
      <ng-container *ngFor="let item of filterList; let i = index">
        <label class="ba-input-checkbox-print" *ngIf="item.active && item.selected">
          <input
            id="{{ filterFacettenName | sgNormanizeId }}_{{ item.key }}_CheckboxMobilePrint"
            type="checkbox"
            [(ngModel)]="item.selected"
          />
          <span
            id="{{ filterFacettenName + '_' + item.key + '_labelMobilePrint' }}"
            aria-hidden="true"
            class="ba-input-checkbox-text-print"
            innerHtml="{{ item.label }}"
          ></span>
        </label>
      </ng-container>
      <div *ngIf="!filterList || filterList.length === 0">
        <label class="ba-input-checkbox">
          <span
            id="{{ filterFacettenName | sgNormanizeId }}_keineErgebnissePrint"
            class="ba-input-checkbox-text-print"
            [innerHTML]="keineErgebnisse"
          ></span>
        </label>
      </div>
      <div *ngIf="filterList && filterList.length > 0 && !hasSelected()">
        <label class="ba-input-checkbox">
          <span
            id="{{ filterFacettenName | sgNormanizeId }}_alle"
            class="ba-input-checkbox-text-print"
            >Alle</span
          >
        </label>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="isFilterFacetteVisible()">
  <!-- mobile version -->
  <fieldset class="ba-filterfacet d-print-none" *ngIf="showMobile">
    <ba-bub-collapsible-facette
      [id]="erzeugeId() + '-mobil'"
      [icon]="icon ? ' ba-icon ba-icon-' + icon + '-before studisu-icon-' + icon + '-before' : ''"
      [facettenName]="filterFacettenName"
      [anzahlSelektierterFilter]="0"
    >
      <ng-container *ngTemplateOutlet="facettenInhalt"></ng-container>
    </ba-bub-collapsible-facette>
  </fieldset>
</ng-container>
