import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StudisuConfig, getStudisuConfig } from '../../../config/studisu.config';
import { ServiceConstants } from '../../../services/serviceconstants';
import { ISelectItem } from './model/ISelectItem';
import { Suchwort } from './model/Suchwort';
import { UrlParamService } from './url-param.service';

@Injectable()
export class SuchworteService {
  private conf: StudisuConfig = getStudisuConfig();

  constructor(
    private http: HttpClient,
    private urlParamService: UrlParamService
  ) {}

  /**
   * Diese Methode sucht nach den "Suchworten".
   * Dabei beruecksichtigt die Suche auch den Umkreis von bis zu drei Staedten (Bundesweit)
   * @param searchTerm Suchbegriff
   */
  public suchen(searchTerm: string): Observable<ISelectItem[]> {
    const url = this.conf.studienangeboteServiceHost + this.conf.suchworteServicePath;
    return this.urlParamService.currentParams.pipe(
      map(() => this.urlParamService.getUrlParamsForBackend({ ac: searchTerm })),
      switchMap((params) => this.http.get<any[]>(url, { params })),
      catchError(() => throwError(() => ServiceConstants.SERVICE_NOT_AVAILABLE)),
      map((suchworte) =>
        suchworte.map(
          (suchwort: any) =>
            new Suchwort(suchwort.name, suchwort.name, suchwort.treffer, suchwort.anbietername)
        )
      )
    );
  }
}
