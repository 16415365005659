<!-- Deutschland- und Europa-Karte -->
<ng-template #facettenInhalt>
  <ba-bub-karte [moeglicheLaender]="kartenItems" (regionClicked)="regionClicked($event)">
  </ba-bub-karte>
</ng-template>

<ng-container *ngIf="hasOptions">
  <div *ngIf="!showMobile; else mobileFacette">
    <ba-bub-facette
      id="bundesland"
      icon="ba-icon-work-in-germany"
      facettenName="Region/Land"
      [showReset]="showReset"
      [facettenInhalt]="facettenInhalt"
      [druckansicht]="druckansicht"
      (resetClicked)="resetFilters()"
      [headingLevel]="3"
    >
    </ba-bub-facette>
  </div>
</ng-container>

<ng-template #mobileFacette>
  <ba-bub-collapsible-facette
    [id]="'bundesland-mobil'"
    [icon]="'ba-icon-work-in-germany'"
    [facettenName]="'Region/Land'"
    [anzahlSelektierterFilter]="0"
  >
    <ng-container *ngTemplateOutlet="facettenInhalt"></ng-container>
  </ba-bub-collapsible-facette>
</ng-template>

<!-- Druckansicht -->
<ng-template #druckansicht>
  <div>
    <ng-container *ngFor="let item of kartenItems; let i = index">
      <label *ngIf="item.active && item.selected">
        <input
          *ngIf="item.selected"
          type="checkbox"
          class="studisu-bl-checkbox-print"
          id="bundesland-cb-pr-{{ item.key }}"
          name="bundesland-cb-{{ item.key }}"
          [checked]="item.selected"
        />
        <span
          id="{{ item.key + '_labelMobilePrint' }}"
          aria-hidden="true"
          class="ba-input-checkbox-text-print"
          innerHtml="{{ item.ariaLabel }}"
        ></span>
      </label>
    </ng-container>
    <div *ngIf="kartenItems && kartenItems.length > 0 && !hasSelected">
      <label class="ba-input-checkbox">
        <span id="BundeslandfacettePrint_alleText" class="ba-input-checkbox-text-print">Alle</span>
      </label>
    </div>
  </div>
</ng-template>
