<ba-bub-typeahead
  #typeahead
  id="TextSuche"
  label="{{ messages.SUCHTYP_SUCHEINGABE_LABEL }}"
  iconClass="studisu-textsuche ba-icon {{ suchtyp?.icon }}-before d-print-none"
  [placeholder]="isMaxParameterCountExceeded ? placeholderDisabledText() : suchtyp?.placeholderText"
  [loadSuggestions]="loadSuggestions"
  [suggestionRenderer]="suggestionRenderer"
  [disable]="isMaxParameterCountExceeded"
  (suggestionSelected)="onSuggestionSelected($event)"
>
</ba-bub-typeahead>

<div class="alert alert-error" role="alert" *ngIf="checkForOrtParam()">
  {{ studienfachNichtVorhanden }}
</div>

<ba-bub-tagcloud
  *ngIf="tagCloudItems.length > 0"
  id="studienbereich_tagcloud-2"
  tagListeAriaLabel="{{ gewaehlteStudienbereicheLabel }}"
  headline="{{ gewaehlteStudienbereicheLabel }}:"
  removeAllTooltip="{{ deleteAllTooltip }}"
  [tagcloudListe]="tagCloudItems$ | async"
  [maxTagsVisible]="3"
  ariaLabel="{{ messages.WEITERE_STUDIENBEREICHE }}"
  ariaLabelContent="{{ messages.ZUSAETZLICHE_STUDIENBEREICHE }}"
  labelForOpen="{{ messages.GEWAEHLTE_STUDIENBEREICHE_ANZEIGEN }}"
  labelForClose="{{ messages.GEWAEHLTE_STUDIENBEREICHE_AUSBLENDEN }}"
  removeTemplate="{{ studienfelderDelegate.removeTooltip }}"
  (tagcloudListeClick)="studienfelderDelegate.clickHandler($event)"
  (tagcloudListeRemoveAll)="bereinigeStudienbereicheUndSuchworteFilter()"
  (tagcloudListeRemove)="remove($event)"
>
</ba-bub-tagcloud>
