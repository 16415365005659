import { AsyncPipe, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LiveAnnouncer, LoggingService } from '@infosysbub/ng-lib-dpl3';
import { Observable, of as observableOf, shareReplay } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { createImageSources } from 'src/app/utils/createImageSources';
import { StudisuErrorService } from '../../error/studisuerror.service';
import { FacettenResult } from '../../model/FacettenResult';
import { PageType } from '../../model/PageType';
import { SearchResult } from '../../model/SearchResult';
import { EventService } from '../../services/event.service';
import { StudienangeboteService } from '../../services/studienangebote.service';
import { HistoryService } from '../../ui-components/back/history.service';
import {
  Breadcrumbs,
  ResponsiveKontextInfoHeaderComponent,
  Source
} from '../../ui-components/responsive-kontext-info-header/responsive-kontext-info-header.component';
import { ResetAllFacettesComponent } from './facettes/reset-all-facettes/reset-all-facettes.component';
import { SucheFacettesComponent } from './facettes/suche-facettes.component';
import { SucheFormComponent } from './form/suche-form.component';
import { SucheResultComponent } from './result/suche-result.component';
import { UrlParamService } from './services/url-param.service';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [StudienangeboteService],
  selector: 'ba-studisu-suche',
  styleUrls: ['./suche.component.scss'],
  templateUrl: './suche.component.html',
  standalone: true,
  imports: [
    ResponsiveKontextInfoHeaderComponent,
    SucheFormComponent,
    NgIf,
    ResetAllFacettesComponent,
    SucheFacettesComponent,
    SucheResultComponent,
    AsyncPipe
  ]
})
export class SucheComponent implements OnInit, AfterViewInit {
  // Reference for the PageType enum
  pageType = PageType;

  public searchResult: SearchResult;

  @ViewChild('ueberschrift')
  private element: ElementRef;

  private RELOAD_PARAMETERS = [
    UrlParamService.PARAM_HOCHSCHULART,
    UrlParamService.PARAM_ABSCHLUSSGRAD,
    UrlParamService.PARAM_ORTE,
    UrlParamService.PARAM_STUDIENFAECHER,
    UrlParamService.PARAM_STUDIENFELDER,
    UrlParamService.PARAM_STUDIENFORMEN,
    UrlParamService.PARAM_FFSTUDIUM,
    UrlParamService.PARAM_STUDT,
    UrlParamService.PARAM_UK,
    UrlParamService.PARAM_REGION,
    UrlParamService.PARAM_STUDIENMODELL,
    UrlParamService.PARAM_STUDIENANBIETER,
    UrlParamService.PARAM_PAGE,
    UrlParamService.PARAM_SUCHWORTE
  ];
  //Achtung die previous Variable, die in dem filter verwendet, wird ist dort eine globale Variable
  private previous: string = null;
  private filterParameter$ = this.route.queryParams.pipe(
    filter((params) => {
      let clone = {};
      for (let reloadParam of this.RELOAD_PARAMETERS) {
        if (params.hasOwnProperty(reloadParam)) {
          clone[reloadParam] = params[reloadParam];
        }
      }
      let json = JSON.stringify(clone);

      let ret = false;
      // Nur wenn sich ein relevanter Parameter geändert hat, laden wir neu.
      if (this.previous === null || this.previous !== json) {
        ret = true;
      }

      this.previous = json;
      return ret;
    }),
    shareReplay()
  );

  public studienangebote$ = this.filterParameter$.pipe(
    switchMap((params) => {
      /**
       * Scroll hoch und fokussiere Ueberschrift, wenn von der Duales-Studium-Kachel der Startseite nach dualen Studiengaengen gesucht
       * wird. Such-Komponente wird dann nicht neu geladen und führt onInit mit richtigem Fokus und Scrollverhalten nicht aus, deshalb
       * muss hier erneut geprueft werden.
       */
      if (this.istNurDualesStudium(params)) {
        window.scroll(0, 0);
        this.fokusUeberschrift();
      }
      if (this.searchResult == null) {
        this.searchResult = new SearchResult();
      }
      this.eventService.studienangeboteSucheStatus.next(true);
      return this.studienangeboteService.getStudienangebote().pipe(
        /**
         * Hier fangen wir auftretende Fehler des Service-Calls und behandeln sie entsprechend.
         * Würden wir die Fehlerbehandlung nicht hier sondern im subscribe machen, wuerde ein Fehler dazu
         * fuehren, dass die gesamte Subscription - also die auf die Route - gecancelt wuerde. Das wollen
         * wir nicht, da die Komponente dann keine Aenderungen an der URL mehr behandeln wuerde.
         */
        catchError((err) => {
          this.eventService.studienangeboteSucheStatus.next(err);
          return observableOf(new SearchResult());
        }),
        tap(() => this.eventService.studienangeboteSucheStatus.next(false))
      );
    })
  );

  public aggregations$ = this.filterParameter$.pipe(
    switchMap(() => {
      return this.studienangeboteService.getFacettenWerte().pipe(
        /**
         * Hier fangen wir auftretende Fehler des Service-Calls und behandeln sie entsprechend.
         * Würden wir die Fehlerbehandlung nicht hier sondern im subscribe machen, wuerde ein Fehler dazu
         * fuehren, dass die gesamte Subscription - also die auf die Route - gecancelt wuerde. Das wollen
         * wir nicht, da die Komponente dann keine Aenderungen an der URL mehr behandeln wuerde.
         */
        catchError((err) => {
          this.logger.error('Aggreggation Service Request Failed with error: ' + err, this);
          return observableOf(new FacettenResult());
        })
      );
    })
  );

  constructor(
    private route: ActivatedRoute,
    private studienangeboteService: StudienangeboteService,
    private urlParamService: UrlParamService,
    private logger: LoggingService,
    private eventService: EventService,
    private studisuErrorService: StudisuErrorService,
    private announcer: LiveAnnouncer,
    private historyService: HistoryService
  ) {}

  public breadcrumbs$: Observable<Breadcrumbs> = this.historyService.breadcrumbs$;

  headerSources: Source[] = createImageSources('assets/images/header/Start');

  public ngOnInit() {
    // STUDISU-105: falls kein Studienfeld oder -fach in URL enthalten, kein Serverzugriff
    this.announcer.announce('Studiensuche Seite geladen');
    window.scrollTo(0, 0);
  }

  public ngAfterViewInit() {
    this.fokusUeberschrift();
  }

  private fokusUeberschrift() {
    if (this.element !== undefined) {
      this.element.nativeElement.focus();
    }
  }

  /**
   * gibt zurück, ob die Suche nur nach Dualem Studium ausgefuehrt werden soll
   * Dies ist der Fall, wenn direkt aus der Duales-Studium-Kachel der Startseite eingesprungen wurde
   *
   * @param params auszuwertende .URL-Params
   * @return ob nur nach Dualem Studium ohne weitere Suchparams gesucht wurde
   */
  private istNurDualesStudium(params) {
    return (
      params[UrlParamService.PARAM_STUDIENFELDER] == null &&
      params[UrlParamService.PARAM_STUDIENFAECHER] == null &&
      this.urlParamService.isDualesStudiumSet(params) &&
      params[UrlParamService.PARAM_SUCHWORTE] == null
    );
  }
}
