import { inject } from '@angular/core';
import { CanActivateFn, Params, Router } from '@angular/router';
import { UrlParamService } from '../services/url-param.service';

/**
 * Guard der Suchergebnisseite.
 *
 * Kontrolliert, ob mindestens eines der folgenden Kriterien erfüllt ist:
 *  - Mindestens ein Studienfeld wurde gewählt
 *  - Mindestens ein Studienfach wurde gewählt
 *  - Mindestens ein Suchwort wurde gewählt
 *  - Duale Studiengänge sollen angezeigt werden.
 *
 *  Wird keines der vorgenannten Kriterien erfüllt, werden alle Query-Parameter verworfen und
 *  es wird zur Startseite navigiert. Einzig die Parameter der Suchleiste, also der Suchtyp, die Orte und der Umkreis,
 *  werden präserviert.
 */
export const sucheGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const queryParamsSuchleiste: Params = {
    [UrlParamService.PARAM_SUCHTYP]: route.queryParamMap.get(UrlParamService.PARAM_SUCHTYP),
    [UrlParamService.PARAM_ORTE]: route.queryParamMap.get(UrlParamService.PARAM_ORTE),
    [UrlParamService.PARAM_UK]: route.queryParamMap.get(UrlParamService.PARAM_UK)
  };
  return (
    istSucheGewuenscht(route.queryParams) ||
    router.createUrlTree([''], {
      queryParams: queryParamsSuchleiste
    })
  );
};

/**
 * Gibt zurück, ob eine Suche nach Studienangeboten ausgeführt werden soll.
 * Dies ist der Fall, wenn weder ein Studienfeld- noch ein Studienfach-URL-Parameter gesetzt ist
 * @param params auszuwertende URL-Query-Params
 * @return wahr gdw. eine Suche nach Studienangeboten ausgeführt werden soll
 */
function istSucheGewuenscht(params: Params) {
  const hasStudienfelder = params[UrlParamService.PARAM_STUDIENFELDER] != null;
  const hasStudienfaecher = params[UrlParamService.PARAM_STUDIENFAECHER] != null;
  const urlParamService = inject(UrlParamService);
  const isDualesStudium = urlParamService.isDualesStudiumSet(params);
  const hasSuchwort = params[UrlParamService.PARAM_SUCHWORTE] != null;
  return hasStudienfelder || hasStudienfaecher || isDualesStudium || hasSuchwort;
}
