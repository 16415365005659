import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Params, RouterLink } from '@angular/router';
import { KachelModule, MinimalCoreModule } from '@infosysbub/ng-lib-dpl3';
import { map } from 'rxjs/operators';
import { BerufepoolService } from '../../../services/berufepool/berufepool.service';
import { News } from '../../../services/berufepool/model/news';
import { TeaserComponent } from '../../news/teaser/teaser.component';
import { UrlParamService } from '../../suche/services/url-param.service';

@Component({
  selector: 'ba-studisu-startseite-content',
  templateUrl: './startseite-content.component.html',
  styleUrls: ['./startseite-content.component.scss'],
  standalone: true,
  imports: [KachelModule, RouterLink, MinimalCoreModule, NgIf, TeaserComponent, AsyncPipe]
})
export class StartseiteContentComponent {
  constructor(
    private urlParamService: UrlParamService,
    public berufepoolService: BerufepoolService
  ) {}

  public news$ = this.berufepoolService
    .getAllBerufepoolNews()
    .pipe(map((result) => News.sortiereNews(result, true).slice(0, 2)));

  getStudienfelderQueryParams(): {} {
    return this.urlParamService.getQueryParams();
  }

  getLehramtQueryParams(): {} {
    const lehramtParams: Params = { [UrlParamService.PARAM_STUDIENFELDER]: '94333;94334' };
    return this.urlParamService.getQueryParams(lehramtParams);
  }

  getDualesStudiumQueryParams(): {} {
    const dualesStudiumParams: Params = { [UrlParamService.PARAM_STUDIENMODELL]: 5 };
    return this.urlParamService.getQueryParams(dualesStudiumParams);
  }
}
