import { Studienangebot } from '../main/suche/services/model/Studienangebot';

/**
 * SearchResult returned by our REST-API studienangebot
 *
 * using optional parameters only
 * as in error scenarios we want to return empty results which is easier this way
 */
export class SearchResult {
  constructor(
    public items: Studienangebot[] = [],
    public maxErgebnisse = 0, // value from the Backend is capped at 10000 (for exact value use FacettenResults maxErgebnisse)
    public hasSearched = false,
    public studienangeboteIds: string[] = []
  ) {}
}
