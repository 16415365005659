import { Injectable } from '@angular/core';
import { LoggingService, SelectedSuggestion, Suggestion } from '@infosysbub/ng-lib-dpl3';
import { BehaviorSubject, Observable, Subscription, of as observableOf } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Messages } from '../../../ui-components/model/Messages';
import { Suchwort } from './model/Suchwort';
import { SuchworteService } from './suchworte.service';
import { UrlParamService } from './url-param.service';

/**
 * Das Delegate erlaubt das Laden und die Verwendung von Suchworten.
 *
 * Die bereitgestellten Methoden können in den Livecycle-Hooks der nutzenden Komponenten
 * aufgerufen werden und erlauben so die Wiederverwendung des Codes dieses Delegates.
 *
 * Insbesondere wird die URL mit einer Auswahl von Suchworten synchron gehalten, was
 * auf der Sucheseite verwendet wird.
 */
export const MAX_SW_PARAMS = 3;
@Injectable()
export class SuchworteDelegate {
  private static AUTOSUGGEST_MINLEN = 2;
  private static VALIDATE_REGEX =
    '^(\\s*|[\\sa-zA-ZäöüàâæçèéêëîïôœùûÿÀÂÆÇÈÉÊËÎÏÔŒÙÛŸÄÖÜß/().,-]+)$';

  public removeTooltip = Messages.TAG_STUDIENFELD_TOOLTIP_REMOVE;
  public selektierteSuchworte: Suchwort[] = [];

  public isReady = new BehaviorSubject(false);

  private urlParamSubscription: Subscription;

  constructor(
    private urlParamService: UrlParamService,
    private suchwortService: SuchworteService,
    private loggingService: LoggingService
  ) {}

  /**
   * Initialisierung der Komponente
   * Hier wird der zugehoerige Url Parameter ausgelesen und
   * verarbeitet
   */
  public init() {
    this.urlParamSubscription = this.urlParamService.currentParams
      .pipe(
        map((params) => params.get(UrlParamService.PARAM_SUCHWORTE)),
        distinctUntilChanged()
      )
      .subscribe((suchworte) => ({
        next: this.parseUrlParams(suchworte),
        complete: () => this.isReady.next(true)
      }));
  }

  /**
   *  Objekt nimmt sich aus den Subscriptions raus
   */
  public destroy() {
    if (this.urlParamSubscription) {
      this.urlParamSubscription.unsubscribe();
    }
  }

  public get isMaxParameterCountExceeded(): boolean {
    return this.selektierteSuchworte.length >= MAX_SW_PARAMS;
  }

  public get autosuggestMinLen(): number {
    return SuchworteDelegate.AUTOSUGGEST_MINLEN;
  }

  public get maxParameterCountExceededErrorMessage(): string {
    return Messages.ZU_VIELE_SUCHWORTE.replace('%max%', '' + MAX_SW_PARAMS);
  }

  /**
   * Lade-Funktion fuer die Auto-Complete-Komponente.
   *
   * @param {string} suchtext Der eingebene Suchtext
   * @returns {SelectItem[]} die Auto-Complete-Verschlaege
   */
  public loadSuggestions(suchtext: string): Observable<Suggestion[]> {
    suchtext = suchtext.trim();
    let valid = new RegExp(SuchworteDelegate.VALIDATE_REGEX, 'i').test(suchtext);
    if (!valid) {
      return observableOf([Suggestion.error(Messages.SUCHWORTE_EINGABEPARAMETER_FEHLERHAFT)]);
    }
    if (!suchtext || suchtext.length < SuchworteDelegate.AUTOSUGGEST_MINLEN) {
      return observableOf([]);
    }

    return this.suchwortService.suchen(suchtext).pipe(
      map((suchergebnis: Suchwort[]) => {
        if (suchergebnis.length === 0) {
          return [Suggestion.error(Messages.KEINE_VORSCHLAEGE_GEFUNDEN)];
        } else {
          return suchergebnis.map((eintrag) => Suggestion.of(eintrag.label, eintrag, null));
        }
      })
    );
  }

  /**
   * Fügt die Auswahl aus dem Autosuggest als Suchwort hinzu.
   *
   * @param auswahl Das aus dem Auto-Suggest ausgewählte Item.
   */
  public addAuswahl(auswahl: SelectedSuggestion) {
    let suchwort = auswahl.value;
    let index = this.selektierteSuchworte.findIndex((x: Suchwort) => x.key === suchwort.key);
    // Suchwort in der Vorschlagsliste, aber noch nicht in der Auswahlliste?
    if (suchwort != null && index === -1) {
      this.selektierteSuchworte.push(suchwort);
      this.updateUrlParamSw();
    }
  }

  /**
   * Aktualisiert die aktuelle Selektion von Suchworten mit dem UrlParamService.
   */
  public updateUrlParamSw() {
    let urlParamSuchworte: string = this.selektierteSuchworte
      // map / bilde ab von Suchwort[] auf string[]
      .map((suchwort: Suchwort) => suchwort.name)
      // join / stringconcateniere das Array aller Suchworte zu einem String mit angegebenem trennzeichen
      .join(UrlParamService.VALUE_SEPARATOR);

    this.urlParamService.updateView({
      [UrlParamService.PARAM_SUCHWORTE]: urlParamSuchworte,
      [UrlParamService.PARAM_PAGE]: 1
    });
  }

  /**
   * Setzt die Suche bezüglich Suchworten sowie gewaehlter Facetten vollständig zurück.
   * Behalten werden nur die Suchworte für Regionen.
   */
  public bereinigeSuchworteFilter() {
    this.urlParamService.resetFilter();
    this.urlParamService.updateView({
      [UrlParamService.PARAM_SUCHWORTE]: null,
      [UrlParamService.PARAM_PAGE]: null
    });
  }

  /**
   * De-selektiert ein Suchwort.
   *
   * @param item Das zu entfernende Item.
   */
  public remove(item: any) {
    if (item instanceof Suchwort) {
      this.removeSw(item);
    }
  }

  /**
   * Callback u.a. für die Checkbox, selektiert oder de-selektiert ein Suchwort.
   *
   * @param suchwort Das umzuschaltende Suchwort
   */
  private removeSw(suchwort: Suchwort) {
    let index = this.selektierteSuchworte.indexOf(suchwort);
    if (index < 0) {
      this.selektierteSuchworte.push(suchwort);
    } else {
      this.selektierteSuchworte.splice(index, 1);
    }

    // STUDISU-45
    // falls der letzte studienbereich/das letzte suchwort geloescht wurde,
    // sollen die selektierten facettenfilter verworfen werden
    if (
      !this.urlParamService.isStudienfeldOrStudienfachSet() &&
      this.selektierteSuchworte.length === 0
    ) {
      this.urlParamService.resetFilter();
    }

    this.updateUrlParamSw();
  }

  /**
   * Helper-Funktion zum Parsen der Suchworte aus der Url.
   *
   * @param suchworteParamValue QueryParams
   */
  private parseUrlParams = (suchworteParamValue: string) => {
    // Felder sw
    let suchworteParamValues: string[] = suchworteParamValue
      ? suchworteParamValue.split(UrlParamService.VALUE_SEPARATOR)
      : [];

    // Genau die Suchworte in die Selektion, die in der URL vorkommen.
    this.selektierteSuchworte = suchworteParamValues.map(
      (suchwort: string) => new Suchwort(suchwort, suchwort)
    );
    if (this.selektierteSuchworte.length > MAX_SW_PARAMS) {
      this.selektierteSuchworte = this.selektierteSuchworte.slice(0, MAX_SW_PARAMS);
      this.loggingService.debug('suchwortedelegate.parseUrlParams() begrenzt suchworte aus url');
    }
  };
}
