<ba-studisu-responsive-kontext-info-header
  [headline]="'Studienbereiche entdecken!'"
  [intro]="
    'Die Auswahl an Studienorten und  Studienfächern ist vielfältig. Die Studiensuche unterstützt bei der optimalen Auswahl.'
  "
  [sources]="sources"
>
</ba-studisu-responsive-kontext-info-header>

<div class="container-fluid">
  <ba-studisu-suche-form></ba-studisu-suche-form>
  <ba-studisu-startseite-content></ba-studisu-startseite-content>
</div>
