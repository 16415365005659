<ba-bub-facette
  id="suchumkreis"
  icon="{{ icon ? ' ba-icon ba-icon-' + icon + '-before' : '' }}"
  facettenName="Suchumkreis"
  [showReset]="false"
  [facettenInhalt]="facettenInhalt"
  [druckansicht]="druckansicht"
  *ngIf="!showMobile; else mobileFacette"
  [headingLevel]="3"
>
</ba-bub-facette>

<ng-template #facettenInhalt>
  <ba-bub-such-umkreis-slider
    [id]="'SuchumkreisSlider'"
    [disabled]="disabled"
    [moeglicheWerte]="moeglicheWerte()"
    [aktuellerWert]="currentSelection"
    [modifikatorFuerBeschreibung]="umkreisMitKm"
    (auswahlUmkreis)="onChange($event)"
  >
  </ba-bub-such-umkreis-slider>
</ng-template>

<ng-template #druckansicht>
  <div>
    <ba-bub-such-umkreis-slider
      [id]="'SuchumkreisSliderPrint'"
      [disabled]="disabled"
      [moeglicheWerte]="moeglicheWerte()"
      [aktuellerWert]="currentSelection"
      [modifikatorFuerBeschreibung]="umkreisMitKm"
    ></ba-bub-such-umkreis-slider>
  </div>
</ng-template>

<!--mobile-->
<ng-template #mobileFacette>
  <ba-bub-collapsible-facette
    [id]="'GewaehlterUmkreisMobile'"
    [icon]="icon ? ' ba-icon ba-icon-' + icon + '-before' : ''"
    [facettenName]="'Suchumkreis'"
    [anzahlSelektierterFilter]="0"
  >
    <ng-container *ngTemplateOutlet="facettenInhalt"></ng-container>
  </ba-bub-collapsible-facette>
</ng-template>
