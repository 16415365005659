<ba-bub-main-layout>
  <router-outlet></router-outlet>
  <ba-studisu-versioninfo></ba-studisu-versioninfo>
</ba-bub-main-layout>

<ba-bub-modal-popup
  #errorModal
  modalId="errorModal"
  modalTitle="{{ modalTitle }}"
  primaryButtonText="{{ messages.ERNEUT_VERSUCHEN }}"
  secondaryButtonText="{{ messages.ABBRECHEN }}"
  [errorModal]="true"
  (primaryButtonClick)="reloadPage()"
  >{{ modalText }}
</ba-bub-modal-popup>

<ba-studisu-modal-hinweisdialog #infodialog></ba-studisu-modal-hinweisdialog>
