<section class="ba-tilerow">
  <ba-bub-kachel
    title="Alle Studienfelder"
    img="assets/images/Kachel_Studienfelder.jpg"
    [queryParams]="getStudienfelderQueryParams()"
    [routerLink]="['/studienfelder']"
    class="col-lg-4 col-sm-6 col-12"
    id="ewk_studienfelder"
  >
    Start in neue Studienwelten! Alle Studienfelder und Studienfächer, Alternativen prüfen und
    Angebote aufrufen...
  </ba-bub-kachel>

  <ba-bub-kachel
    title="Lehrämter"
    img="assets/images/Kachel_Lehramt.jpg"
    [queryParams]="getLehramtQueryParams()"
    [routerLink]="['/studienfeldinfo']"
    class="col-lg-4 col-sm-6 col-12"
    id="ewk_lehraemter"
  >
    Sekundarstufe I oder II, Grundschule, Berufsschule oder Förderschule, viele Fächerkombinationen
    und zahlreiche Studienorte...
  </ba-bub-kachel>

  <ba-bub-kachel
    title="Zwei in Eins: Duales Studium"
    img="assets/images/Kachel_Duales_Studium.jpg"
    [queryParams]="getDualesStudiumQueryParams()"
    [routerLink]="['/suche']"
    class="col-lg-4 col-sm-6 col-12"
    id="ewk_dualesstudium"
  >
    Studium und Praxiserfahrung clever kombiniert! Zahlreiche Informationen zu Hochschulen,
    Studienmodellen und Unternehmen...
  </ba-bub-kachel>
</section>

<section class="ba-cardrow-wrap">
  <div class="row">
    <div class="col-md-6 col-xl-3">
      <article id="studienberatung" class="ba-tile ba-layoutless d-flex flex-column">
        <div
          class="ba-image"
          style="background-image: url(assets/images/studienberatung.jpg)"
        ></div>
        <h2 class="ba-heading h5">Studienberatung</h2>

        <div class="ba-copytext">
          <p>
            Persönliche Beratung und Hilfe bei der Studienplatzwahl, Studienorientierung oder
            Fachwechsel. Jetzt direkt Kontakt aufnehmen.
          </p>
        </div>

        <footer class="mt-auto">
          <a
            id="studienberatung-link"
            href="https://web.arbeitsagentur.de/portal/kontakt/de/terminvereinbarung/berufsberatung"
            target="_blank"
            class="ba-btn ba-btn-block ba-btn-secondary"
          >
            <span>Termin vereinbaren</span>
          </a>
        </footer>
      </article>
    </div>
    <div class="col-md-6 col-xl-3">
      <article class="ba-tile" id="weitere-links-und-tests">
        <h2 class="ba-heading" id="weitere-links-titel">Weiterführende Links</h2>

        <ul class="ba-linklist" id="weitere-links-liste">
          <li>
            <a
              href="https://www.arbeitsagentur.de/bildung/studium/wege-ins-ausland-im-studium"
              class="ba-link-icon ba-icon-linkout"
              target="_blank"
              >Wege ins Ausland - relevante Infos rund um Auslandssemester und internationales
              Studium</a
            >
          </li>
          <li>
            <a
              href="https://www.arbeitsagentur.de/bildung/studium/finanzielle-unterstuetzung-studium"
              class="ba-link-icon ba-icon-linkout"
              target="_blank"
              >Finanzielle Unterstützung im Studium</a
            >
          </li>
          <li>
            <a
              href="https://berufenet.arbeitsagentur.de/berufenet/"
              class="ba-link-icon ba-icon-linkout"
              target="_blank"
            >
              BERUFENET - umfangreiche Informationen über alle Studienfächer und Berufe
            </a>
          </li>
        </ul>

        <h2 class="h5 mt-0" id="studi-tests-titel">Online-Tests Thema Studium</h2>

        <ul class="ba-linklist" id="studi-tests-liste">
          <li>
            <a
              href="https://www.arbeitsagentur.de/bildung/welche-ausbildung-welches-studium-passt"
              class="ba-link-icon ps-1"
              target="_blank"
            >
              <img
                src="assets/images/logo-check-u.png"
                class="logo-check-u"
                alt="Selbsterkundungstool Check-U aufrufen"
              />
            </a>
          </li>
          <li>
            <a href="https://studiencheck.de/" class="ba-link-icon ps-1" target="_blank">
              <img
                src="assets/images/studiencheck.png"
                class="logo-studiencheck"
                style=""
                alt="Studiencheck - Wie fit bin ich für mein Studium?"
              />
            </a>
          </li>
        </ul>
      </article>
    </div>
    <div class="col-lg-12 col-xl-6" *ngIf="news$ | async as news">
      <article class="ba-tile" id="news">
        <h2 class="ba-heading">News und Informationen</h2>
        <div class="ba-copytext">
          <p>Interessante Neuigkeiten rund um Studium, neue Studiengänge sowie Studienbewerbung.</p>
        </div>
        <ba-studisu-news-teaser [news]="news$ | async"></ba-studisu-news-teaser>
        <footer class="col-md-6 offset-md-3 col-xl-6 offset-xl-3">
          <button
            id="news-page-link"
            class="ba-btn ba-btn-block ba-btn-secondary"
            [routerLink]="'/news'"
          >
            <span>Alle Neuigkeiten anzeigen</span>
          </button>
        </footer>
      </article>
    </div>
  </div>
</section>
