import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { sucheGuard } from '../../suche/guard/suche-guard.service';

/**
 * Guard der Startseite. Ist Umkehrung des SucheGuards.
 * Navigiert, wenn die Query-Parameter es erlauben, direkt auf die Suchseite.
 */

export const startseiteGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const isNotSuche = sucheGuard(route, state) instanceof UrlTree;
  return (
    isNotSuche ||
    router.createUrlTree(['/suche'], {
      queryParamsHandling: 'merge',
      queryParams: route.queryParams
    })
  );
};
