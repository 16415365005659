import { ErrorHandler, Provider, inject } from '@angular/core';
import { LoggingService } from '@infosysbub/ng-lib-dpl3';
import { StudisuError } from './studisu-error';

export class StudisuErrorHandler implements ErrorHandler {
  private loggingService = inject(LoggingService);

  handleError(error: Error) {
    if (error instanceof StudisuError) {
      this.loggingService.error(error.message, error);
    }
  }
}

export function provideStudisuErrorHandler(): Provider {
  return { provide: ErrorHandler, useClass: StudisuErrorHandler };
}
